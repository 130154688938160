import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export { NavigationBar };

function NavigationBar({ navBarStatus}) {
    const auth = useSelector(x => x.auth.value);

    // only show nav when logged in
    if (!auth) return null;

    return (
        <nav className={`sidebar ${navBarStatus === 'open' ? 'sidebar-open' : 'sidebar-close'}`}>
            <div className="cnt-logo">
                <img className="logo" src={`${process.env.PUBLIC_URL}/assets/logo/logo_VM24_w.svg`} alt="vm24 logo"/>
            </div>
            <ul className="sidebar-nav">
                <li className="sidebar-nav__item">
                    <NavLink to="/" className="sidebar-nav__item-link"><i className="bi bi-house-gear me-2"></i> Dashboard</NavLink>
                </li>
                <li className="sidebar-nav__item">
                    <NavLink to="/" className="sidebar-nav__item-link"><i className="bi bi-person-gear me-2"></i> Gestione Profilo</NavLink>
                </li>
                <li className="sidebar-nav__item">
                    <NavLink to="/" className="sidebar-nav__item-link"><i className="bi bi-postcard me-2"></i>Gestione Licenza</NavLink>
                </li>
            </ul>
        </nav>
    );
}
