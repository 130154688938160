import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { alertActions } from '../_store';
import { history, fetchWrapper } from '../_helpers';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        value: JSON.parse(localStorage.getItem('auth'))
    }
}

function createReducers() {
    return {
        setAuth
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_NR_API_BASE_URL + '/mobileuser/api/v1';
    const baseQueryParams = 'appDescriptorSlug='+process.env.REACT_APP_NR_APP_DESCRIPTOR_SLUG+'&appDescriptorSalt='+process.env.REACT_APP_NR_APP_DESCRIPTOR_SALT;

    return {
        login: login(),
        logout: logout()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async function ({ username, password }, { dispatch }) {
                dispatch(alertActions.clear());
                try {
                    const loginUrl = baseUrl+"/mobileuser/logins?"+baseQueryParams;
                    const userResultData = await fetchWrapper.post(loginUrl, { userLogin: username, password: password });
                    // set auth user in redux state
                    if(!userResultData.success) {
                        dispatch(alertActions.error(userResultData.message));
                        return;
                    }
                    const userData = userResultData.data;

                    dispatch(authActions.setAuth(userData));

                    // store user details in local storage to keep user logged in between page refreshes
                    localStorage.setItem('auth', JSON.stringify(userData));

                    console.log(localStorage.getItem('auth'));

                    // get return url from location state or default to home page
                    //const { from } = history.location.state || { from: { pathname: '/' } };
                    //history.navigate(from);
                } catch (error) {
                    dispatch(alertActions.error(error));
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function (arg, { dispatch }) {
                dispatch(authActions.setAuth(null));
                localStorage.removeItem('auth');
                history.navigate('/account/login');
            }
        );
    }
}
