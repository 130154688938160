import { store, authActions } from '../_store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        //console.log(requestOptions);
        //const base64auth = btoa(process.env.REACT_APP_NR_API_WS_USER+":"+process.env.REACT_APP_NR_API_WS_PWD);
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    // recuperare da storage il token dell'utente - la basic auth sarà sempre fatta con i dati presi dall'env...
    //const token = authToken();
    //const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_NR_API_BASE_URL);
    //const base64auth = Buffer.from(process.env.REACT_APP_NR_API_WS_USER+":"+process.env.REACT_APP_NR_API_WS_PWD, 'base64');
    const base64auth = btoa(process.env.REACT_APP_NR_API_WS_USER+":"+process.env.REACT_APP_NR_API_WS_PWD);
    const auth = "Basic " + base64auth;
    //if (isLoggedIn && isApiUrl) {
    if (isApiUrl) {
        return {
            'Authorization': auth
        };
    } else {
        return {};
    }
}

function authToken() {
    return store.getState().auth.value?.token;
}

async function handleResponse(response) {
    const isJson = response.headers?.get('content-type')?.includes('application/json');
    const data = isJson ? await response.json() : null;

    // check for error response
    if (!response.ok) {
        if ([401, 403].includes(response.status) && authToken()) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            const logout = () => store.dispatch(authActions.logout());
            logout();
        }

        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
    }

    return data;
}
