import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from "react";
import { alertActions, userActions } from "../_store";
import { Alert, Header } from "../_components";
import { format } from "date-fns";
import {Link} from "react-router-dom";

export { Home };

function Home({setNavBarStatusHandler}) {
    const auth = useSelector(x => x.auth.value)
    const dispatch = useDispatch();

    // State for user data and license data
    const [userData, setUserData] = useState(null);
    const [licenseData, setLicenseData] = useState(null);

    // Function to fetch user data
    const handleDashboardData = useCallback(async () => {
        try {
            const userParams = {
                username: auth?.username,
                secretToken: auth?.secretToken,
            };
            const userDataResult = await dispatch(userActions.getByUsername(userParams)).unwrap();

            if (!userDataResult.success) {
                dispatch(alertActions.error(userDataResult.message));
                return;
            }

            // Set user data and check if license data needs to be set
            setUserData(userDataResult.data);
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }, [dispatch, auth]);  // Only depend on 'dispatch' and 'auth' for user data fetching

    // Fetch license data only after user data is set
    useEffect(() => {
        if (userData) {
            if(userData.license) {
                userData.license.start_datetime = format(new Date(), userData.license.start_datetime.split('T')[0]);
                userData.license.end_datetime = format(new Date(), userData.license.end_datetime.split('T')[0]);
            }
            setLicenseData(userData.license);  // Set license data once user data is available
        }
    }, [userData]);  // Only trigger this when 'userData' is updated

    // Fetch user data when the component mounts or auth changes
    useEffect(() => {
        handleDashboardData();
    }, [handleDashboardData]);  // Avoid infinite loop by not depending on 'userData'

    return (
        <div className="content flex-grow-1">
            <Header setNavBarStatusHandler={setNavBarStatusHandler} />
            <Alert />
            <div className="container">
                <div className="row">
                    <div className="col-md-6 cnt-card">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <span className="flex-grow-1">Info Utente</span>
                                {userData && userData.is_active && <span>Attivo <i className="bi bi-circle-fill text-success"></i></span>}
                                {userData && !userData.is_active && <span>Non Attivo <i className="bi bi-circle-fill text-danger"></i></span>}
                            </div>
                            <div className="card-body">
                                <div className="card-text h-100">
                                    {userData ? (
                                        <div className="d-flex flex-column h-100">
                                            <div className="flex-grow-1">
                                                <p><strong>Username:</strong> {userData.user_login}</p>
                                                <hr/>
                                                <p><strong>Ragione Sociale:</strong> {userData.company_name}</p>
                                                <p><strong>Nome:</strong> {userData.first_name} {userData.last_name}</p>
                                                {/*<p><strong>Telefono:</strong> {userData.registry.phone_number}</p>*/}
                                                {/*<p><strong>Email:</strong> {userData.registry.email_contact}</p>*/}
                                                {/*<p><strong>P.IVA:</strong> {userData.registry.vat}</p>*/}
                                                {/*<p><strong>Sdi:</strong> {userData.registry.sdi}</p>*/}
                                            </div>
                                            <hr/>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <button className="btn btn-vm24">
                                                    {/*{isRetryingPayment &&*/}
                                                    {/*    <span className="spinner-border spinner-border-sm me-1"></span>}*/}
                                                    Gestisci Dati
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <p><span className="spinner-border spinner-border-sm me-1"></span> Caricamento
                                            dati utente...</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 cnt-card">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <span className="flex-grow-1">Info Licenza</span>
                                {licenseData && licenseData.is_active && (!licenseData.is_expiring && !licenseData.is_expired) && <span>Attiva <i className="bi bi-circle-fill text-success ms-1"></i></span>}
                                {licenseData && !licenseData.is_active && (licenseData.is_expiring || licenseData.is_expired) && <span>Non Attiva<i className="bi bi-circle-fill text-danger ms-1"></i></span>}
                                {licenseData && licenseData.is_expired && <span>Scaduta<i className="bi bi-circle-fill text-danger ms-1"></i></span>}
                                {licenseData && licenseData.is_expiring && <span>In scadenza<i className="bi bi-circle-fill text-warning ms-1"></i></span>}
                                {licenseData && (licenseData.is_expiring || licenseData.is_expired) &&
                                    <Link to={`license/${licenseData.lookappId}/renew`} className="btn btn-small btn-vm24-main ms-2">
                                        {/*{isRetryingPayment &&*/}
                                        {/*    <span className="spinner-border spinner-border-sm me-1"></span>}*/}
                                        Rinnova
                                    </Link>
                                }
                            </div>
                            <div className="card-body">
                                <div className="card-text h-100">
                                    {licenseData ? (
                                        <div className="d-flex flex-column h-100">
                                            <div className="flex-grow-1">
                                                <p className="d-flex align-items-center">
                                                    <strong>ID:</strong> #{licenseData.lookappId}
                                                    {licenseData.is_active && <span className="badge rounded-pill text-bg-success ms-2">Attiva</span>}
                                                    {!licenseData.is_active && <span className="badge rounded-pill text-bg-danger ms-2">NON Attiva</span>}
                                                </p>
                                                <p><strong>Data apertura:</strong> {format(licenseData.start_datetime, 'dd/MM/yyyy')}</p>
                                                <p>
                                                    <strong>Data scadenza:</strong> {format(licenseData.end_datetime, 'dd/MM/yyyy')}
                                                    {licenseData.is_expiring && <i className="bi bi-exclamation-triangle-fill text-warning ms-3" title="In scadenza"></i> }
                                                    {licenseData.is_expired && <i className="bi bi-exclamation-triangle-fill text-danger ms-3" title="Scaduta"></i> }
                                                </p>
                                                <p>
                                                    <span
                                                        className="me-3"><strong>DA configurabili:</strong> {licenseData.max_vendingmachine}</span>
                                                    <span><strong>DA attivi:</strong> {userData.active_vendingmachine}/{licenseData.max_vendingmachine}</span>
                                                </p>
                                                {licenseData.bluetooth_active && <p><strong>Mod. Bluetooth:</strong> SI</p>}
                                                {!licenseData.bluetooth_active &&
                                                    <p><strong>Mod. Bluetooth:</strong> NO</p>}
                                            </div>
                                            <hr/>
                                            <div className="d-flex justify-content-end flex-column align-items-end">
                                                <div>
                                                    <span>Aggiungi Distributori Automatici</span>
                                                    <Link to={`license/${licenseData.lookappId}/sit/add`} className="btn btn-vm24 ms-2">
                                                        {/*{isRetryingPayment &&*/}
                                                        {/*    <span className="spinner-border spinner-border-sm me-1"></span>}*/}
                                                        Procedi
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <p><span className="spinner-border spinner-border-sm me-1"></span> Caricamento
                                            dati licenza...</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
