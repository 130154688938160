import {Link, useParams } from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';

//import {history} from '../_helpers';
import {userActions, alertActions, paymentActions} from '../_store';
import {useCallback, useEffect, useState} from "react";
import {Alert, Header} from "../_components";
import {format} from "date-fns";

export { RenewLicense };

function RenewLicense({setNavBarStatusHandler}) {
    const auth = useSelector(x => x.auth.value);
    const licenseId = useParams().lId;
    const dispatch = useDispatch();
    const licensePrice = process.env.REACT_APP_NR_PRICE_LICENSE;
    const sitPrice = process.env.REACT_APP_NR_PRICE_SIT;

    let [totalSits, setTotalSits] = useState(0);
    let [totalVat, setTotalVat] = useState(Number(licensePrice*22)/100);
    let [totalCart, setTotalCart] = useState(Number(licensePrice)+Number(totalVat));

    // State for user data and license data
    const [userData, setUserData] = useState(null);
    const [licenseData, setLicenseData] = useState(null);

    // Function to fetch user data
    const handleSitData = useCallback(async () => {
        try {
            const userParams = {
                username: auth?.username,
                secretToken: auth?.secretToken,
            };
            const userDataResult = await dispatch(userActions.getByUsername(userParams)).unwrap();

            if (!userDataResult.success) {
                dispatch(alertActions.error(userDataResult.message));
                return;
            }
            // Set user data and check if license data needs to be set
            setUserData(userDataResult.data);
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }, [dispatch, auth]);  // Only depend on 'dispatch' and 'auth' for user data fetching
    // Fetch user data when the component mounts or auth changes
    useEffect(() => {
        handleSitData();
    }, [handleSitData]);  // Avoid infinite loop by not depending on 'userData'

    // Fetch license data only after user data is set
    useEffect(() => {
        if (userData) {
            //check if user license match with licenseId requested
            if(Number(userData.license.lookappId) !== Number(licenseId)) {
                dispatch(alertActions.error('Licenza non valida o non appartenente a questo account'));
                return;
            }
            if(userData.license) {
                userData.license.start_datetime = format(new Date(), userData.license.start_datetime.split('T')[0]);
                userData.license.end_datetime = format(new Date(), userData.license.end_datetime.split('T')[0]);
            }
            setLicenseData(userData.license);  // Set license data once user data is available
            if(licenseData && licenseData.max_vendingmachine) {
                handleChangeSits({target: {value: licenseData.max_vendingmachine}});
            }
            console.log("userData: ",userData);
            console.log("licenseData: ",licenseData);
        }
    }, [userData, dispatch, licenseId]);  // Only trigger this when 'userData' is updated

    // form validation rules
    const validationSchema = Yup.object().shape({
        paymentMethod: Yup.string()
            .required('Metodo di pagamento obbligatorio'),
        licenseBaseValue: Yup.string().matches(/^[0-9]/, 'Licenza base deve contenere solo numeri'),
        sitsValue: Yup.string().matches(/^[0-9]/, 'DA deve contenere solo numeri').default(String(sitPrice)),
        vatValue: Yup.string().matches(/^[0-9]/, 'IVA deve contenere solo numeri').default(String(totalVat)),
        totalValue: Yup.string().matches(/^[0-9]/, 'Totale deve contenere solo numeri').default(String(totalCart)),
        sits: Yup.number().min(1, 'Numero minimo di distributori 1').default(1)
    });
    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState, setValue} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    const handleChangeSits = (e) => {
        setValue("sits",e.target.value);
        const currentValue = (e.target.value)-1;
        const sitsTotal = currentValue * sitPrice;
        const vatTotal = ((Number(licensePrice) + Number(sitsTotal))*22)/100;
        setTotalSits(Number(sitsTotal));
        setValue("sitsValue", Number(sitsTotal));
        setTotalVat(Number(vatTotal));
        setValue("totalVat", Number(vatTotal));
        const valTotal = Number(licensePrice) + Number(sitsTotal) + Number(vatTotal);
        setTotalCart(Number(valTotal));
        setValue("totalValue", Number(valTotal));
    }

    const [paymentMethod, setPaymentMethod] = useState('credit-card');
    // Handle radio button click (change value)
    const handleRadioPaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const formattedPrice = (price) => {
        return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(price);
    }

    const onSubmit = async(formData) => {
        console.log('Form Data:', formData);  // Check if data is being passed
        console.log('Errors:', errors);   // Check if there are any validation errors
        dispatch(alertActions.clear());
        try {
            //aggiungo dati utente e dati licenze a formData
            const paymentFormData = {
                lId: licenseData.lookappId,
                uId: auth.username,
                uToken: auth.secretToken,
                licenseBaseValue: licensePrice,
                sitsValue: formData.sitsValue,
                totalVat: formData.vatValue,
                totalValue: formData.totalValue,
                totalSits: formData.sits,
                description: 'Rinnovo Licenza',
                type: 'renewal'
            } ;
            console.log(paymentFormData);
            const paymentResult = await dispatch(paymentActions.create(paymentFormData)).unwrap();
             console.log(paymentResult);
            if(!paymentResult.success) {
                dispatch(alertActions.error(paymentResult.message));
            } else {
                const paymentData = paymentResult.data; //data => 'transactionId','paymentId','paymentUrl'
                //redirect to payment gateway
                window.location.href = paymentData.paymentUrl;
            }
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <div className="content flex-grow-1">
            <Header setNavBarStatusHandler={setNavBarStatusHandler}/>
            <Alert/>
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-3">
                        <h1>Gestione Licenza - Rinnovo</h1>
                    </div>
                    <form name="form-license" onSubmit={handleSubmit(onSubmit)} className="col-12 form_license mt-3">
                        {(userData && licenseData)? (
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="section-cart-title">Riepilogo Dati</h4>
                                    <hr/>
                                    <ul className="list-unstyled ps-3 pt-3">
                                        <li><strong>Utente:</strong> {userData?.first_name} {userData?.last_name}</li>
                                        <li><strong>Ragione Sociale:</strong> {userData?.company_name}</li>
                                        {licenseData && <li><strong>Licenza:</strong> #{licenseData?.lookappId}</li>}
                                        {licenseData && <li><strong>Valida fino al:</strong> {licenseData && format(licenseData.end_datetime, 'dd/MM/yyyy')}</li>}
                                        {licenseData &&
                                            <li><strong>DA configurabili:</strong> {licenseData?.max_vendingmachine}</li>}

                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="section-cart-title">Riepilogo Carrello</h4>
                                    <hr/>
                                    <div className="d-flex align-items-center justify-content-between mb-1 mt-3">
                                        <label className="form-label flex-fill mb-0">Licenza Base (1 DA incluso)</label>
                                        <span className="cart-price-field">{formattedPrice(licensePrice / 100)}</span>
                                        <input name="licenseBaseValue" type="hidden" {...register('licenseBaseValue')}
                                               value={licensePrice} disabled className="form-control w-auto"/>
                                    </div>
                                    <div
                                        className={`d-flex align-items-center justify-content-between ${errors.sits ? 'is-invalid' : ''}`}>
                                        <label className="form-label flex-fill mb-0">N° Distributori Automatici
                                            (DA)</label>
                                        <input name="sits" type="number" {...register('sits')}
                                               min="1" defaultValue={1} onChange={handleChangeSits}
                                               className={`form-control w-auto ${errors.sits ? 'is-invalid' : ''}`}/>
                                    </div>
                                    <div className="invalid-feedback">{errors.sits?.message}</div>
                                    <p className="alert alert-info mt-3">Devi aggiungere 10 o più Distributori
                                        Automatici? Contattaci per avere una quotazione personalizzata al <a
                                            href="tel:+393394177451">+39 339 4177451</a> oppure scrivi una mail a <a
                                            href="mailto:corrispettivi@vm24.app">corrispettivi@vm24.app</a>
                                    </p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <label className="form-label flex-fill mb-0">Distributori Automatici
                                            (aggiuntivi)</label>
                                        <span className="cart-price-field">{formattedPrice(totalSits / 100)}</span>
                                        <input name="sitsValue" type="hidden" {...register('sitsValue')}
                                               value={totalSits} disabled className="form-control w-auto"/>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <label className="form-label flex-fill mb-0">IVA (22%)</label>
                                        <span className="cart-price-field">{formattedPrice(totalVat / 100)}</span>
                                        <input name="vatValue" type="hidden" {...register('vatValue')}
                                               value={totalVat} disabled className="form-control w-auto"/>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <label className="form-label flex-fill fw-bold mb-0">Totale</label>
                                        <span
                                            className="cart-price-field fw-bold">{formattedPrice(totalCart / 100)}</span>
                                        <input name="totalValue" type="hidden" {...register('totalValue')}
                                               value={totalCart} disabled className="form-control w-auto"/>
                                    </div>
                                    <h4 className="section-cart-title mt-3">Metodo di pagamento</h4>
                                    <hr />
                                    <div className="mb-3">
                                        <div className={`${errors.paymentMethod ? 'is-invalid' : ''}`}>
                                            {/*<label className="form-label">termsAndPrivacy</label>*/}
                                            <div className="d-flex align-items-center">
                                                <input type="radio" id="paymentMethodCreditCard" name="paymentType"
                                                       checked={paymentMethod === 'credit-card'}
                                                       onChange={handleRadioPaymentChange}
                                                       value="credit-card" {...register('paymentMethod')}
                                                       className="form-check"/>
                                                <label htmlFor="paymentMethodCreditCard" className="ms-1">Carta di
                                                    Credito</label>
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">{errors.paymentMethod?.message}</div>
                                    </div>
                                    <div className="col-md-12">
                                        <button disabled={isSubmitting} className="btn btn-vm24 mt-3 mb-3 w-100">
                                            {isSubmitting &&
                                                <span className="spinner-border spinner-border-sm me-1"></span>}
                                            Conferma e Acquista
                                        </button>
                                        <Link to="/" className="btn btn-link mb-3">Annulla/Torna indietro</Link>
                                    </div>
                                </div>
                            </div>
                            ) : (
                                <div className="row">
                                   <div className="col-12 d-flex justify-content-center align-items-center">
                                       <span className="spinner-border spinner-border-sm me-1"></span> Recupero dati in corso...</div>
                                </div>
                            )
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}
