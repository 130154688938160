import { Routes, Route } from 'react-router-dom';
//import { useSelector } from 'react-redux';

import {ErrorPayment, SuccessPayment} from './';

export { PaymentLayout };

function PaymentLayout() {
    //const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
    /*if (auth) {
        return <Navigate to="/" />;
    }*/

    return (
        <Routes>
            <Route path="error" element={<ErrorPayment />} />
            <Route path="success" element={<SuccessPayment />} />
        </Routes>
    );
}
