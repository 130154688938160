import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { history } from './_helpers';
import { PrivateRoute, Footer } from './_components';
import { DashboardLayout } from './dashboard';
import { AccountLayout } from './account';
import { PaymentLayout } from './payment';
//import { UsersLayout } from './users';
import { LicenseLayout } from './license';
import {useState} from "react";

export { App };

function App() {
    // init custom history object to allow navigation from
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    const [navBarStatus, setNavBarStatus] = useState('open');

    const setNavBarStatusHandler = () => {
        setNavBarStatus(prevStatus => (prevStatus === 'open' ? 'close' : 'open'));
    }

    return (
        <div className="app-container">
            <Routes>
                {/* private */}
                <Route element={<PrivateRoute />}>
                    <Route path="/*" element={<DashboardLayout navBarStatus={navBarStatus} setNavBarStatusHandler={setNavBarStatusHandler} />} />
                    {/*<Route path="users/*" element={<UsersLayout setNavBarStatusHandler={setNavBarStatusHandler} />} />*/}
                    <Route path="license/*" element={<LicenseLayout navBarStatus={navBarStatus}  setNavBarStatusHandler={setNavBarStatusHandler} />} />
                </Route>
                {/* public */}
                <Route path="account/*" element={<AccountLayout />} />
                <Route path="payment/*" element={<PaymentLayout />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
        </div>
    );
}
