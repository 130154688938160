import {Link} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';

//import {history} from '../_helpers';
import {userActions, licenseActions, paymentActions, alertActions} from '../_store';
import {useState} from "react";
import {Alert} from "../_components";

export {Register};

function Register() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();

    const licensePrice = process.env.REACT_APP_NR_PRICE_LICENSE;
    const sitPrice = process.env.REACT_APP_NR_PRICE_SIT;

    let [isCheckout, setIsCheckout] = useState(0);
    let [dataCheckout, setDataCheckout] = useState(null);
    let [dataSubmitting, setDataSubmitting] = useState(false);

    const handleIsCheckout = () => {
        setIsCheckout(isCheckout ? 0 : 1);
        if(!isCheckout) {
            setDataSubmitting(false);
        }
    }
    const handleDataSubmitting = () => {
        setDataSubmitting(dataSubmitting ? false : true);
    }

    let [totalSits, setTotalSits] = useState(0);
    let [totalVat, setTotalVat] = useState(Number(licensePrice*22)/100);
    let [totalCart, setTotalCart] = useState(Number(licensePrice)+Number(totalVat));

    // form validation rules
    const validationSchema = Yup.object().shape({
        termsAndPrivacy: Yup.boolean()
            .required('Termini e Privacy obbligatoria').oneOf([true], 'Termini e Privacy obbligatoria'),
        commercials: Yup.boolean(),
        paymentMethod: Yup.string()
            .required('Metodo di pagamento obbligatorio'),
        firstName: Yup.string()
            .required('Nome obbligatorio'),
        lastName: Yup.string()
            .required('Cognome obbligatorio'),
        companyName: Yup.string()
            .required('Ragione sociale obbligatoria'),
        email: Yup.string()
            .required('Email obbligatoria'),
        phoneNumber: Yup.string()
            .required('Telefono obbligatorio'),
        fiscalCode: Yup.string(),
        sdiCode: Yup.string(),
        licenseBaseValue: Yup.string().matches(/^[0-9]/, 'Licenza base deve contenere solo numeri'),
        sitsValue: Yup.string().matches(/^[0-9]/, 'DA deve contenere solo numeri').default(String(totalSits)),
        vatValue: Yup.string().matches(/^[0-9]/, 'IVA deve contenere solo numeri').default(String(totalVat)),
        totalValue: Yup.string().matches(/^[0-9]/, 'Totale deve contenere solo numeri').default(String(totalCart)),
        sits: Yup.number().min(1, 'Numero minimo di distributori 1').default(1),
        vat: Yup.string().required('Partiva IVA obbligatoria').length(11, "P.IVA deve contenere 11 caratteri").matches(/^[0-9]{11}$/, 'P.IVA deve contenere solo numeri'),
        username: Yup.string().required('Username (p.iva) obbligatoria').length(11, "P.IVA deve contenere 11 caratteri").matches(/^[0-9]{11}$/, 'P.IVA deve contenere solo numeri'),
        password: Yup.string().required('Password obbligatoria').min(6, 'Password deve contenere almeno 6 caratteri'),
        repeatPassword: Yup.string()
            .required('Ripeti Password obbligatoria')
            .oneOf([Yup.ref('password'), null], 'Le password devono coincidere')
            .min(6, 'Ripeti Password deve contenere almeno 6 caratteri')
    });
    const formOptions = {resolver: yupResolver(validationSchema)};
    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState, setValue} = useForm(formOptions);
    const {errors, isSubmitting} = formState;
    setValue('licenseBaseValue', Number(licensePrice));

    const handleChangeSits = (e) => {
        setValue("sits",e.target.value);
        const currentValue = (e.target.value)-1;
        const sitsTotal = currentValue * sitPrice;
        const vatTotal = ((Number(licensePrice) + Number(sitsTotal))*22)/100;
        setTotalSits(Number(sitsTotal));
        setValue("sitsValue", Number(sitsTotal));
        setTotalVat(Number(vatTotal));
        setValue("totalVat", Number(vatTotal));
        const valTotal = Number(licensePrice) + Number(sitsTotal) + Number(vatTotal);
        setTotalCart(Number(valTotal));
        setValue("totalValue", Number(valTotal));
    }

    const [inputVatValue, setInputVatValue] = useState('');
    const handleInputVatChange = (e) => {
        setValue("username", e.target.value); //update react-hook-form value
        setInputVatValue(e.target.value); // update state
    }

    const [paymentMethod, setPaymentMethod] = useState('credit-card');
    // Handle radio button click (change value)
    const handleRadioPaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    }

    const formattedPrice = (price) => {
        return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(price);
    }

    const [passwordVisible, setPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(prevState => !prevState);
    }
    const [passwordRepeatVisible, setPasswordRepeatVisible] = useState(false);
    const togglePasswordRepeatVisibility = () => {
        setPasswordRepeatVisible(prevState => !prevState);
    }

    const onPreSubmit = async(formData) => {
        console.log('Form Data:', formData);  // Check if data is being passed
        console.log('Errors:', errors);   // Check if there are any validation errors
        setDataCheckout(formData);
        setIsCheckout(1);
        // dispatch(alertActions.clear());
        // try {
        //     //console.log(data);
        //     //creo utente (non attivo)
        //     const {sits,licenseBaseValue,sitsValue,totalValue,totalVat,...userFormData} = {...formData} ;
        //     //console.log(userData);
        //     const userResult = await dispatch(userActions.register(userFormData)).unwrap();
        //     console.log(userResult);
        //     if(!userResult.success) {
        //         dispatch(alertActions.error(userResult.message));
        //     } else {
        //         //recupero dati utenti da payload
        //         const userData = userResult.data;
        //         //creo licenza
        //         const licenseFormData = {
        //             sits: formData.sits,
        //             uToken: userData.secretToken,
        //             uId: userData.username
        //         } ;
        //         const licenseResult = await dispatch(licenseActions.create(licenseFormData)).unwrap();
        //         console.log(licenseResult);
        //         if(!licenseResult.success) {
        //             dispatch(alertActions.error(licenseResult.message));
        //         } else {
        //             const licenseData = licenseResult.data;
        //             //creo pagamento
        //             const paymentFormData = {
        //                 lId: licenseData.id,
        //                 uId: userData.username,
        //                 uToken: userData.secretToken,
        //                 licenseBaseValue: formData.licenseBaseValue,
        //                 sitsValue: formData.sitsValue,
        //                 totalVat: formData.vatValue,
        //                 totalValue: formData.totalValue,
        //                 totalSits: formData.sits,
        //                 description: "Acquisto nuova licenza",
        //                 type: "new"
        //             } ;
        //             const paymentResult = await dispatch(paymentActions.create(paymentFormData)).unwrap();
        //             // console.log(paymentResult);
        //             if(!paymentResult.success) {
        //                 dispatch(alertActions.error(paymentResult.message));
        //             } else {
        //                 const paymentData = paymentResult.data; //data => 'transactionId','paymentId','paymentUrl'
        //                 //redirect to payment gateway
        //                 window.location.href = paymentData.paymentUrl;
        //             }
        //         }
        //     }
        // } catch (error) {
        //     dispatch(alertActions.error(error));
        // }
    }

    const onSubmit = async() => {
        handleDataSubmitting()
        dispatch(alertActions.clear());
        try {
            //console.log(data);
            //creo utente (non attivo)
            const {sits,licenseBaseValue,sitsValue,totalValue,totalVat,...userFormData} = {...dataCheckout} ;
            //console.log(userData);
            const userResult = await dispatch(userActions.register(userFormData)).unwrap();
            console.log(userResult);
            if(!userResult.success) {
                setDataSubmitting(false);
                dispatch(alertActions.error(userResult.message));
            } else {
                //recupero dati utenti da payload
                const userData = userResult.data;
                //creo licenza
                const licenseFormData = {
                    sits: dataCheckout.sits,
                    uToken: userData.secretToken,
                    uId: userData.username
                } ;
                const licenseResult = await dispatch(licenseActions.create(licenseFormData)).unwrap();
                console.log(licenseResult);
                if(!licenseResult.success) {
                    setDataSubmitting(false);
                    dispatch(alertActions.error(licenseResult.message));
                } else {
                    const licenseData = licenseResult.data;
                    //creo pagamento
                    const paymentFormData = {
                        lId: licenseData.id,
                        uId: userData.username,
                        uToken: userData.secretToken,
                        licenseBaseValue: dataCheckout.licenseBaseValue,
                        sitsValue: dataCheckout.sitsValue,
                        totalVat: dataCheckout.vatValue,
                        totalValue: dataCheckout.totalValue,
                        totalSits: dataCheckout.sits,
                        description: "Acquisto nuova licenza",
                        type: "new"
                    } ;
                    const paymentResult = await dispatch(paymentActions.create(paymentFormData)).unwrap();
                    // console.log(paymentResult);
                    if(!paymentResult.success) {
                        setDataSubmitting(false);
                        dispatch(alertActions.error(paymentResult.message));
                    } else {
                        const paymentData = paymentResult.data; //data => 'transactionId','paymentId','paymentUrl'
                        //redirect to payment gateway
                        window.location.href = paymentData.paymentUrl;
                    }
                }
            }
        } catch (error) {
            setDataSubmitting(false);
            dispatch(alertActions.error(error));
        }
    }

    return (
        <div className={`container flex-grow-1 ${auth ? 'reserved-area' : ''}`}>
            <div className="row box-security">
                <div className="col-md-6 img_container img_container-register">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/features.png`} alt="vm24 app"
                         className="img_register-img"/>
                    <img src={`${process.env.PUBLIC_URL}/assets/logo/logo_VM24_b.svg`} alt="vm24 logo"
                         className="img_register-logo"/>
                </div>
                {/*info*/}
                <div className="col-md-6 align-self-center">
                    <div className="txt-pill txt-pill-green mb-3">
                        <h3 className="txt-green">Registrati e accedi</h3>
                        <p>Crea il tuo profilo inserendo i dati fiscali, username e password.</p>
                    </div>
                    <div className="txt-pill txt-pill-yellow mb-3">
                        <h3 className="txt-green">Acquista la licenza</h3>
                        <p>Specifica il numero di vending machine che gestisci e completa l’acquisto della licenza per
                            abilitare tutte le funzionalità dell’app.</p>
                    </div>
                    <div className="txt-pill txt-pill-green-light">
                        <h3 className="txt-green-light">Scarica e attiva la tua app</h3>
                        <p>Scarica l'app, attiva VM24 e configura le vending machine dal pannello di controllo per
                            iniziare a gestire i corrispettivi nella massima semplicità.</p>
                    </div>
                </div>
                {/*<form name="form-login" onSubmit={(e) => {*/}
                {/*    console.log("Form is being submitted");*/}
                {/*    handleSubmit(onSubmit)(e);*/}
                {/*}} className="col-12 form_account">*/}
                <form name="form-login" onSubmit={handleSubmit(onPreSubmit)} className="col-12 form_account">
                    <div className={`row ${!isCheckout ? 'd-flex' : 'd-none'} `}>
                        <div className="col-12 licence-data">
                            <h1 className="text-center">VM24 - Acquisto Nuova Licenza</h1>
                        </div>
                        <div className="col-md-6">
                            {/*dati utente*/}
                            <h4 className="section-cart-title">Dati utente</h4>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">Nome</label>*/}
                                    <input placeholder="Nome" name="firstName" type="text" {...register('firstName')}
                                           className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.firstName?.message}</div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">Cognome</label>*/}
                                    <input placeholder="Cognome" name="lastName" type="text" {...register('lastName')}
                                           className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.lastName?.message}</div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">Azienda</label>*/}
                                    <input placeholder="Ragione Sociale" name="companyName"
                                           type="text" {...register('companyName')}
                                           className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.companyName?.message}</div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">Email</label>*/}
                                    <input placeholder="Email" name="email" type="text" {...register('email')}
                                           className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">P.IVA</label>*/}
                                    <input placeholder="P.IVA" name="vat" type="text" {...register('vat')}
                                           value={inputVatValue} onChange={handleInputVatChange}
                                           className={`form-control ${errors.vat ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.vat?.message}</div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">CF</label>*/}
                                    <input placeholder="Codice Fiscale" name="fiscalCode"
                                           type="text" {...register('fiscalCode')}
                                           className={`form-control ${errors.fiscalCode ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.fiscalCode?.message}</div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">Telefono</label>*/}
                                    <input placeholder="Telefono" name="phoneNumber"
                                           type="text" {...register('phoneNumber')}
                                           className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/*<label className="form-label">SDI</label>*/}
                                    <input placeholder="SDI" name="sdiCode" type="text" {...register('sdiCode')}
                                           className={`form-control ${errors.sdiCode ? 'is-invalid' : ''}`}/>
                                    <div className="invalid-feedback">{errors.sdiCode?.message}</div>
                                </div>
                            </div>
                            {/*dati di accesso*/}
                            <h4 className="section-cart-title">Dati di accesso</h4>
                            <div className="mb-3">
                                {/*<label className="form-label">P.IVA</label>*/}
                                <input placeholder="Username" name="username" type="text"  {...register('username')}
                                       value={inputVatValue}
                                       className={`form-control ${errors.username ? 'is-invalid' : ''}`} disabled/>
                                <div className="invalid-feedback">{errors.username?.message}</div>
                            </div>
                            <div className="mb-3">
                                {/*<label className="form-label">Password</label>*/}
                                <div className={`input-group ${errors.password ? 'is-invalid' : ''}`}>
                                    <input placeholder="Password" name="password"
                                           type={passwordVisible ? "text" : "password"} {...register('password')}
                                           className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                                    <button type="button" className="btn btn-outline-secondary form-control"
                                            onClick={togglePasswordVisibility}>
                                        <i className={`${passwordVisible ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                    </button>
                                </div>
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>
                            <div className="mb-3">
                                {/*<label className="form-label">Password</label>*/}
                                <div className={`input-group ${errors.password ? 'is-invalid' : ''}`}>
                                    <input placeholder="Conferma Password" name="repeatPassword"
                                           type={passwordRepeatVisible ? "text" : "password"} {...register('repeatPassword')}
                                           className={`form-control ${errors.repeatPassword ? 'is-invalid' : ''}`}/>
                                    <button type="button" className="btn btn-outline-secondary form-control"
                                            onClick={togglePasswordRepeatVisibility}>
                                        <i className={`${passwordRepeatVisible ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                    </button>
                                </div>
                                <div className="invalid-feedback">{errors.repeatPassword?.message}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/*licenza e da - gestione carrello*/}
                            <h4 className="section-cart-title">Licenza e Distributori Automatici</h4>
                            <div className="my-3">
                                <div
                                    className={`d-flex align-items-center justify-content-between ${errors.sits ? 'is-invalid' : ''}`}>
                                    <label className="form-label flex-fill mb-0">N° Distributori Automatici (DA)</label>
                                    <input name="sits" type="number" {...register('sits')}
                                           min="1" defaultValue={1} onChange={handleChangeSits}
                                           className={`form-control w-auto ${errors.sits ? 'is-invalid' : ''}`}/>
                                </div>
                                <div className="invalid-feedback">{errors.sits?.message}</div>
                            </div>
                            <div className="my-3">
                                <div className="d-flex align-items-center justify-content-between mb-1 mt-3">
                                    <label className="form-label flex-fill mb-0">Licenza Base (1 DA incluso)</label>
                                    <span className="cart-price-field">{formattedPrice(licensePrice / 100)}</span>
                                    <input name="licenseBaseValue" type="hidden" {...register('licenseBaseValue')}
                                           value={licensePrice} disabled className="form-control w-auto"/>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <label className="form-label flex-fill mb-0">Distributori Automatici
                                        (aggiuntivi)</label>
                                    <span className="cart-price-field">{formattedPrice(totalSits / 100)}</span>
                                    <input name="sitsValue" type="hidden" {...register('sitsValue')}
                                           value={totalSits} disabled className="form-control w-auto"/>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <label className="form-label flex-fill mb-0">IVA (22%)</label>
                                    <span className="cart-price-field">{formattedPrice(totalVat / 100)}</span>
                                    <input name="vatValue" type="hidden" {...register('vatValue')}
                                           value={totalVat} disabled className="form-control w-auto"/>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <label className="form-label flex-fill fw-bold mb-0">Totale</label>
                                    <span className="cart-price-field fw-bold">{formattedPrice(totalCart / 100)}</span>
                                    <input name="totalValue" type="hidden" {...register('totalValue')}
                                           value={totalCart} disabled className="form-control w-auto"/>
                                </div>
                                <p className="alert alert-info mt-3">Sei in possesso di 10 o più Distributori
                                    Automatici? Contattaci per avere una quotazione personalizzata al <a
                                        href="tel:+393394177451">+39 339 4177451</a> oppure scrivi una mail a <a
                                        href="mailto:corrispettivi@vm24.app">corrispettivi@vm24.app</a></p>
                            </div>
                            {/*metodi di pagamento*/}
                            <h4 className="section-cart-title">Metodo di pagamento</h4>
                            <div className="mb-3">
                                <div className={`${errors.paymentMethod ? 'is-invalid' : ''}`}>
                                    {/*<label className="form-label">termsAndPrivacy</label>*/}
                                    <div className="d-flex align-items-center">
                                        <input type="radio" id="paymentMethodCreditCard" name="paymentType"
                                               checked={paymentMethod === 'credit-card'}
                                               onChange={handleRadioPaymentChange}
                                               value="credit-card" {...register('paymentMethod')}
                                               className="form-check"/>
                                        <label htmlFor="paymentMethodCreditCard" className="ms-1">Carta di
                                            Credito</label>
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.paymentMethod?.message}</div>
                            </div>
                            {/*privacy e terms*/}
                            <h4 className="section-cart-title">Privacy, Termini e Profilazione</h4>
                            <div>
                                <div
                                    className={`d-flex align-items-start ${errors.termsAndPrivacy ? 'is-invalid' : ''}`}>
                                    {/*<label className="form-label">termsAndPrivacy</label>*/}
                                    <input name="termsAndPrivacy" id="termsAndPrivacyId"
                                           type="checkbox" {...register('termsAndPrivacy')}
                                           className="form-check"/>
                                    <label htmlFor="termsAndPrivacyId" className="ms-1">Ho preso visione dei <Link
                                        to="https://www.vm24.app/termini-condizioni" target="_blank">Termini e Condizioni</Link> e della <Link
                                        to="https://www.vm24.app/privacy-statement-eu/" target="_blank">Privacy Policy</Link></label>
                                </div>
                                <div className="invalid-feedback">{errors.termsAndPrivacy?.message}</div>
                            </div>
                            <div className="mb-3">
                                <div className='d-flex align-items-start'>
                                    {/*<label className="form-label">Commercials</label>*/}
                                    <input name="commercials" id="commercialsId"
                                           type="checkbox" {...register('commercials')}
                                           className="form-check"/>
                                    <label htmlFor="commercialsId" className="ms-1">Acconsento all'invio di offerte
                                        commerciali</label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <button disabled={isSubmitting} className="btn btn-vm24 mt-3 mb-3">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                    Registrati e Acquista Licenza
                                </button>
                                <Link to="/account/login" className="btn btn-link mb-3">Annulla</Link>
                            </div>
                        </div>
                    </div>
                    {
                        dataCheckout &&
                        <div className={`row ${isCheckout ? 'd-flex' : 'd-none'} `}>
                            <div className="col-12 licence-data">
                                <h1 className="text-center">VM24 - Riepologo dati / Checkout</h1>
                            </div>
                            <div className="col-md-6">
                                {/*dati utente*/}
                                <h4 className="section-cart-title">Riepologo Dati utente</h4>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        {/*<label className="form-label">Nome</label>*/}
                                        <strong>Nome: </strong><span>{dataCheckout.firstName}</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong>Cognome: </strong><span>{dataCheckout.lastName}</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong>Ragione Sociale: </strong><span>{dataCheckout.companyName}</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong>Email: </strong><span>{dataCheckout.email}</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong>P.Iva: </strong><span>{dataCheckout.vat}</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong>Cod. Fiscale: </strong><span>{dataCheckout.fiscalCode ? dataCheckout.fiscalCode : ' ---' }</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong>Telefono: </strong><span>{dataCheckout.phoneNumber}</span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <strong>Sdi: </strong><span>{dataCheckout.sdi ? dataCheckout.sdi: ' ---'}</span>
                                    </div>
                                </div>
                                {/*dati di accesso*/}
                                <h4 className="section-cart-title">Dati di accesso</h4>
                                <div className="mb-3">
                                    <strong>Username: </strong><span>{dataCheckout.username}</span>
                                </div>
                                <div className="mb-3">
                                    <strong>Password: </strong><span> ******</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/*licenza e da - gestione carrello*/}
                                <h4 className="section-cart-title">Riepilogo Licenza e Distributori Automatici</h4>
                                <div className="my-3">
                                    <strong>Postazioni Distributori Automatici: </strong><span>{dataCheckout.sits}</span>
                                </div>
                                <div className="my-3 box-recap-cart">
                                    <p>
                                        <strong>Prezzo base: </strong><span>{formattedPrice(licensePrice / 100)}</span>
                                    </p>
                                    <p>
                                        <strong>Prezzo DA aggiuntivi: </strong><span>{formattedPrice(totalSits / 100)}</span>
                                    </p>
                                    <p className="pt-2">
                                        <strong>Totale parziale: </strong><span>{formattedPrice(Number(Number(totalSits)+Number(licensePrice)) / 100)}</span>
                                    </p>
                                    <p>
                                        <strong>IVA (22%): </strong><span>{formattedPrice(totalVat / 100)}</span>
                                    </p>
                                    <p className="pt-3">
                                        <strong>Totale: </strong><span>{formattedPrice(totalCart / 100)}</span>
                                    </p>
                                </div>
                                {/*metodi di pagamento*/}
                                <h4 className="section-cart-title">Riepilogo Metodo di pagamento</h4>
                                <div className="mb-3">
                                    <strong>Metodo: </strong><span>{paymentMethod === 'credit-card' ? 'Carta di Credito' : 'Bonifico Bancario'}</span>
                                </div>
                                {/*privacy e terms*/}
                                <div className="col-md-12">
                                    <button onClick={onSubmit} disabled={dataSubmitting} className="btn btn-vm24 mt-3 mb-3">
                                        {dataSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                        Conferma dati e procedi al pagamento
                                    </button>
                                    <button type="button" onClick={handleIsCheckout} className="btn btn-link mt-3 mb-3">
                                        Torna indietro
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </form>
                <Alert/>
            </div>
        </div>
    );
}
