import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

//import { authActions } from '../_store';
import { fetchWrapper } from '../_helpers';

// create slice

const name = 'payment';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const paymentActions = { ...slice.actions, ...extraActions };
export const paymentReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_NR_API_BASE_URL + '/license/api/v1';
    const baseQueryParams = 'appDescriptorSlug='+process.env.REACT_APP_NR_APP_DESCRIPTOR_SLUG+'&appDescriptorSalt='+process.env.REACT_APP_NR_APP_DESCRIPTOR_SALT;
    return {
        create: create(),
        verify: verify(),
        retry: retry()
    };

    function create() {
        const createUrl = baseUrl+"/payment/creates?"+baseQueryParams;
        return createAsyncThunk(
            `${name}/create`,
            async (data) => await fetchWrapper.post(createUrl, data)
        );
    }

    function verify() {
        const verifyUrl = baseUrl+"/payment/verifies?"+baseQueryParams;
        return createAsyncThunk(
            `${name}/verify`,
            async (data) => await fetchWrapper.post(verifyUrl, data)
        );
    }

    function retry() {
        const retryUrl = baseUrl+"/payment/retries?"+baseQueryParams;
        return createAsyncThunk(
            `${name}/retry`,
            async (data) => await fetchWrapper.post(retryUrl, data)
        );
    }
}

function createExtraReducers() {
    return (builder) => {
    }
}
