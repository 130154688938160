//import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";

export { Footer };

function Footer() {
    //const auth = useSelector(x => x.auth.value);
    // if(auth) return null;

    return (
        <section className="footer-main">
            <div className="container">
                <div className="row">
                    <div className="col-md-1 cnt-logo">
                        <img src={`${process.env.PUBLIC_URL}/assets/logo/logo_VM24_w.svg`} alt="vm24 logo"/>
                    </div>
                    <div className="col-md-11 cnt-info">
                        <span className="info">&copy; Netrising Srl</span>
                        <span className="info">P.IVA 02506520390</span>
                        <Link to="https://www.vm24.app/cookie-policy-eu" target="_blank" rel="noreferrer" className="info">Cookie Policy</Link>
                        <Link to="https://www.vm24.app/privacy-statement-eu" target="_blank" rel="noreferrer" className="info">Privacy Policy</Link>
                        <Link to="https://www.vm24.app/termini-condizioni" target="_blank" rel="noreferrer" className="info">Termini e Condizioni</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
