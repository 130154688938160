import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Home } from './';
import { NavigationBar } from "../_components";

export { DashboardLayout };

function DashboardLayout({navBarStatus, setNavBarStatusHandler}) {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
    if (!auth) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="pdc-container">
            <NavigationBar navBarStatus={navBarStatus} />
            <Routes>
                <Route path="/" element={<Home setNavBarStatusHandler={setNavBarStatusHandler} />} />
            </Routes>
        </div>
    );
}
