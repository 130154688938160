import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';

import { authActions } from '../_store';
import {Alert} from "../_components";

export { Login };

function Login() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('P.IVA obbligatoria'),
        password: Yup.string().required('Password obbligatoria')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password }));
    }

    return (
        <div className={`container flex-grow-1 ${auth ? 'reserved-area' : ''}`}>
            <div className="row align-items-center box-security">
                <div className="col-md-6 img_container">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/hand_header.webp`} alt="vm24 login"
                         className="img_login-img"/>
                    <img src={`${process.env.PUBLIC_URL}/assets/logo/logo_VM24_w.svg`} alt="vm24 logo"
                         className="img_login-logo"/>
                </div>
                <div className="col-md-6">
                    <h1 className="text-center">VM24 - Area Riservata</h1>
                    <Alert/>
                    <form name="form-login" onSubmit={handleSubmit(onSubmit)} className="form_account">
                        <div className="mb-3">
                            {/*<label className="form-label">P.IVA</label>*/}
                            <input placeholder="P.IVA" name="username" type="text" {...register('username')}
                                   className={`form-control ${errors.username ? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                        <div className="mb-3">
                            {/*<label className="form-label">Password</label>*/}
                            <input placeholder="Password" name="password" type="password" {...register('password')}
                                   className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <button disabled={isSubmitting} className="btn btn-vm24 mt-3">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Accedi
                        </button>
                        <p className="text-center my-3">oppure</p>
                        <Link to="../register" className="btn btn-link">Registrati e acquista la licenza</Link>
                    </form>
                </div>
            </div>
        </div>
    );
}
