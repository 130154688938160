import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AddSit } from "./AddSit";
import { RenewLicense } from "./RenewLicense";
import {NavigationBar} from "../_components";

export { LicenseLayout };

function LicenseLayout({navBarStatus, setNavBarStatusHandler}) {
    const auth = useSelector(x => x.auth.value);

    // redirect to home if already logged in
    if (!auth) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="pdc-container">
            <NavigationBar navBarStatus={navBarStatus}/>
            <Routes>
                <Route path=":lId/sit/add" element={<AddSit setNavBarStatusHandler={setNavBarStatusHandler}/>}/>
                <Route path=":lId/renew" element={<RenewLicense setNavBarStatusHandler={setNavBarStatusHandler}/>}/>
            </Routes>
        </div>
    );
}
