import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {paymentActions, alertActions} from '../_store';
import {useState, useEffect, useCallback} from "react";
import {Alert} from "../_components";

export {SuccessPayment};

function SuccessPayment() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const [licenseData, setLicenseData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [transactionId, setTransactionId] = useState('---');
    const [paymentId, setPaymentId] = useState('---');
    const [processingData, setProcessingData] = useState(true);
    const [showData, setShowData] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorDetails, setErrorDetails] = useState(null);
    const [isRetryingPayment, setIsRetryingPayment] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    const formattedPrice = (price) => {
        return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(price);
    }

    // useCallback ensures the function doesn't change on every render
    const handleVerifyPayment = useCallback(async (params) => {
        try {
            const verifyResult = await dispatch(paymentActions.verify(params)).unwrap();
            //console.log('verifyResult', verifyResult);
            if (!verifyResult.success) {
                const errorData = {
                    message: verifyResult.message,
                    errorCode: verifyResult.errorCode,
                    errorMessage: verifyResult.errorMessage,
                    canRetryPayment: verifyResult.canRetryPayment
                };
                await setErrorDetails(errorData);
                //console.log('errorData', errorData);
                //console.log('errorDetails', errorDetails);
                if (errorDetails && !errorDetails.canRetryPayment) {
                    await setShowData(false);
                }
                await setUserData(verifyResult.userData);
                await setShowError(true);
                await setProcessingData(false);
            } else {
                const dataResult = verifyResult.data;
                await setUserData(dataResult.userData);
                await setLicenseData(dataResult.licenseData);
                await setPaymentData(dataResult.paymentData);
                await setTransactionId(dataResult.transactionId);
                await setPaymentId(dataResult.paymentId);
                await setProcessingData(false);
            }
        } catch (error) {
            await setShowData(false);
            await setShowError(true);
            dispatch(alertActions.error(error));
        }
    }, [dispatch, errorDetails]);

    useEffect(() => {
        const type = queryParams.get('type');
        const ulId = queryParams.get('ulId').split('#');
        const userId = ulId[0].split('U-')[1];
        const licenseId = ulId[1].split('L-')[1];
        const transactionId = queryParams.get('sId');
        const paymentFormData = {
            type: type,
            uId: userId,
            lId: licenseId,
            sId: transactionId
        };
        handleVerifyPayment(paymentFormData); // Call the function inside useEffect
    }, [location, handleVerifyPayment]); // Include handleVerifyPayment in the dependency array

    const handleRetryPayment = () => async () => {
        setIsRetryingPayment(true);

        const queryParams = new URLSearchParams(location.search);
        const isRenewal = queryParams.get('renewal');
        const ulId = queryParams.get('ulId').split('#');
        const userId = ulId[0].split('U-')[1];
        const licenseId = ulId[1].split('L-')[1];
        const transactionId = queryParams.get('sId');
        const paymentFormData = {
            isRenewal: isRenewal,
            uId: userId,
            lId: licenseId,
            sId: transactionId,
            type: type
        };
        const paymentResult = await dispatch(paymentActions.retry(paymentFormData)).unwrap();
        // console.log(paymentResult);
        if(!paymentResult.success) {
            dispatch(alertActions.error(paymentResult.message));
        } else {
            const paymentData = paymentResult.data; //data => 'transactionId','paymentId','paymentUrl'
            //redirect to payment gateway
            window.location.href = paymentData.paymentUrl;
            //redirect to login page and display success alert
            //history.navigate('/account/login');
            //dispatch(alertActions.success({message: 'Registrazione avvenuta con successo e licenza attivata. Puoi effettuare il login', showAfterRedirect: true}));
        }
    }

    return (
        <div className={`container flex-grow-1 ${auth ? 'reserved-area' : ''}`}>
            <div className="row align-items-center box-security">
                <Alert/>
                <div className="col-md-6 img_container">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/hand_header.webp`} alt="vm24 login"
                         className="img_login-img"/>
                    <img src={`${process.env.PUBLIC_URL}/assets/logo/logo_VM24_w.svg`} alt="vm24 logo"
                         className="img_login-logo"/>
                </div>
                <div className="col-md-6">
                    <h1 className="text-center">VM24 - Esito operazione</h1>
                    <div className="row">
                        <div className="col-12 box-payment-result">
                            {
                                processingData &&
                                <div className="loader-container">
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                    <span className="my-2">Verifica in corso...</span>
                                </div>
                            }
                            <div className="box-payment-result">
                                {
                                    !processingData && showData && !errorDetails &&
                                    <div className="alert alert-success d-flex align-items-start" role="alert">
                                        <i className="bi bi-info-circle flex-shrink-0 me-2"/>
                                        <div className="d-flex flex-column justify-content-start">
                                            <b>Operazione completata con successo</b>
                                            <span>Il pagamento è andato a buon fine. Riceverai una mail di conferma con i
                                            dettagli dell'operazione.</span>
                                        </div>
                                    </div>
                                }
                                {
                                    !processingData && showError && errorDetails &&
                                    <div className="alert alert-danger d-flex align-items-start" role="alert">
                                        <i className="bi bi-exclamation-triangle flex-shrink-0 me-2"/>
                                        <div className="d-flex flex-column justify-content-start">
                                            <b>{errorDetails.errorCode}</b>
                                            <span>{errorDetails.message}</span>
                                            <span>{errorDetails.errorMessage}</span>
                                        </div>
                                    </div>
                                }
                                {
                                    showData &&
                                    <div>
                                        <p>Di seguito il riepilogo dati:</p>
                                        <ul className="list-unstyled ps-3 pt-3">
                                            <li><strong>Utente:</strong> {userData?.firstName} {userData?.lastName}</li>
                                            <li><strong>Ragione Sociale:</strong> {userData?.companyName}</li>
                                            {paymentData && paymentData.type === 'new' &&
                                            <li><strong>Username:</strong> {userData?.username}</li>}
                                            {paymentData && paymentData.type === 'new' &&
                                            <li><strong>Password:</strong> ******</li>}
                                            {licenseData && <li><strong>Licenza:</strong> #{licenseData?.id}</li>}
                                            {licenseData && <li><strong>Validità dal:</strong> {paymentData?.startDate}
                                                <strong> al:</strong> {paymentData?.endDate}</li>}
                                            {licenseData &&
                                                <li><strong>DA configurabili:</strong> {licenseData?.sits}</li>}
                                            {paymentData &&
                                                <li>
                                                    <strong>Postazioni DA acquistate:</strong> {paymentData?.totalSitsRequested}
                                                </li>}
                                            {paymentData &&
                                                <li>
                                                    <strong>Prezzo:</strong> {formattedPrice(paymentData?.totalPrice / 100)}
                                                </li>}
                                            <li><strong>Transazione:</strong> {transactionId}</li>
                                            <li><strong>Id pagamento:</strong> {paymentId}</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {showError && errorDetails && errorDetails.canRetryPayment &&
                        <button onClick={handleRetryPayment()} className="btn btn-vm24 mb-3 w-100">
                            {isRetryingPayment && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Riprova pagamento
                        </button>
                    }
                    {showData && !auth && <Link to="/account/login"
                                       className={`btn btn-link mb-3 ${isRetryingPayment || processingData ? 'disabled' : ''}`}>Effettua
                        il login</Link>}
                    {showData && auth &&
                        <Link to="/" className={`btn btn-link mb-3 ${processingData ? 'disabled' : ''}`}>Torna alla dashboard</Link>}
                    {showError && !showData &&
                        <Link to="/account/login" className={`btn btn-link mb-3 ${processingData ? 'disabled' : ''}`}>Torna alla home</Link>}
                </div>
            </div>
        </div>
    );
}
