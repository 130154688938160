import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from "react-router-dom";
import {authActions} from "../_store";
export { Header };

function Header({setNavBarStatusHandler}) {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    if(!auth) return null;
    const logout = () => dispatch(authActions.logout());

    return (
        <section className="header-main">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                        <button className="btn btn-vm24" onClick={setNavBarStatusHandler}><i className="bi bi-list"></i></button>
                        <NavLink to="/" onClick={logout} className="nav-item nav-link">
                            <i className="bi bi-escape"></i> Logout
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
}
